"use client";

import {
  format,
  startOfWeek,
  endOfWeek,
  previousSunday,
  nextSunday,
} from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";

import { cn } from "@winclap-platform/ui/utils/tailwind";
import { Button } from "@winclap-platform/ui/components/button";
import { Calendar } from "@winclap-platform/ui/components/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@winclap-platform/ui/components/popover";
import { DateRange, DayPickerProps } from "react-day-picker";
import { useCallback, useState } from "react";

type DatePickerMode = "single" | "week" | "range";

type DatePickerBaseProps = {
  label?: string;
  mode?: DatePickerMode;
  className?: string;
  disabledDates?: DayPickerProps["disabled"];
} & Omit<DayPickerProps, "mode" | "disabled">;

type DatePickerDateProps = DatePickerBaseProps & {
  mode: "single" | "week";
  value?: Date;
  disabled?: boolean;
  onChange: (value?: Date) => void;
};
type DatePickerRangeProps = DatePickerBaseProps & {
  mode: "range";
  value?: DateRange;
  disabled?: boolean;
  onChange: (value?: DateRange) => void;
};
export type DatePickerProps = DatePickerDateProps | DatePickerRangeProps;

const SingleDatePicker = ({
  value,
  onChange,
  label,
  disabled,
  disabledDates,
  className,
  ...props
}: DatePickerDateProps) => (
  <Popover>
    <PopoverTrigger asChild>
      <Button
        variant="outline"
        size="sm"
        className={cn(
          "flex w-full justify-between text-left font-normal",
          !value && "text-muted-foreground",
          className,
        )}
        disabled={disabled}
      >
        {value ? format(value, "PPP") : <span>{label}</span>}
        <CalendarIcon className="text-primary-200 group-hover:text-primary-400 size-4" />
      </Button>
    </PopoverTrigger>
    <PopoverContent className="w-auto p-0">
      <Calendar
        {...props}
        mode="single"
        selected={value}
        onSelect={onChange}
        disabled={disabledDates}
      />
    </PopoverContent>
  </Popover>
);

const WeekDatePicker = ({
  value,
  onChange,
  label,
  disabled,
  disabledDates,
  className,
  ...props
}: DatePickerDateProps) => {
  const [currentMonth, setCurrentMonth] = useState(value ? value : new Date());

  const currentWeek = value
    ? {
        from: value,
        to: endOfWeek(value, { weekStartsOn: 1 }),
      }
    : undefined;

  const handleWeekChange = (range?: DateRange) => {
    const newValue = range?.from !== currentWeek?.from ? range?.from : range?.to;
    const from = newValue && startOfWeek(newValue, { weekStartsOn: 1 });
    onChange(from?.getTime() === currentWeek?.from?.getTime() ? undefined : from);
  };

  const handleMonthChange = useCallback((month: Date) => {
    setCurrentMonth(month);
  }, []);

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          size="sm"
          className={cn(
            "flex w-full justify-between text-left font-normal",
            !value && "text-muted-foreground",
            className,
          )}
          disabled={disabled}
        >
          {value ? format(value, "'W'ww / yyyy") : <span>{label}</span>}
          <CalendarIcon className="text-primary-200 group-hover:text-primary-400 size-4" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar
          {...props}
          mode="range"
          showWeekNumber
          firstWeekContainsDate={4}
          weekStartsOn={1}
          selected={currentWeek}
          onSelect={handleWeekChange}
          month={currentMonth}
          onMonthChange={handleMonthChange}
          endMonth={new Date()}
          disabled={
            Array.isArray(disabledDates)
              ? disabledDates
              : disabledDates
                ? [disabledDates]
                : [
                    {
                      after:
                        new Date().getUTCDay() === 6
                          ? nextSunday(Date.now())
                          : previousSunday(Date.now()),
                    },
                  ]
          }
        />
      </PopoverContent>
    </Popover>
  );
};

const RangeDatePicker = ({
  value,
  onChange,
  label,
  disabled,
  disabledDates,
  className,
  ...props
}: DatePickerRangeProps) => {
  const currentRange = value || { from: undefined, to: undefined };

  const handleRangeChange = (range: DateRange | undefined) => {
    if (range) {
      const formattedRange: DateRange = {
        from: range.from,
        to: range.to,
      };
      onChange(formattedRange);
    } else {
      onChange(undefined);
    }
  };

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          size="sm"
          className={cn(
            "flex w-full justify-between text-left font-normal",
            !value && "text-muted-foreground",
            className,
          )}
          disabled={disabled}
        >
          {currentRange.from || currentRange.to ? (
            `${currentRange.from ? format(currentRange.from, "d MMM yyyy") : "..."} to ${
              currentRange.to ? format(currentRange.to, "d MMM yyyy") : "..."
            }`
          ) : (
            <span>{label}</span>
          )}
          <CalendarIcon className="text-primary-200 group-hover:text-primary-400 size-4" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="m-1 w-auto p-0">
        <Calendar
          {...props}
          mode="range"
          min={1}
          selected={currentRange}
          onSelect={handleRangeChange}
          disabled={disabledDates}
        />
      </PopoverContent>
    </Popover>
  );
};

export function DatePicker(props: DatePickerProps) {
  switch (props.mode) {
    case "week":
      return <WeekDatePicker {...props} />;
    case "range":
      return <RangeDatePicker {...props} />;
    case "single":
    default:
      return <SingleDatePicker {...props} />;
  }
}

DatePicker.defaultProps = { label: "Date", mode: "single" };
