"use client";

import { Check, ChevronDown, Loader2 } from "lucide-react";

import { Button } from "@winclap-platform/ui/components/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@winclap-platform/ui/components/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@winclap-platform/ui/components/popover";
import { useState } from "react";

export interface Option<TValue extends string | number> {
  label: string;
  value: TValue;
}

export interface ComboBoxProps<TValue extends string | number> {
  values: TValue[] | undefined;
  onChange: (value: TValue) => void;
  options?: Option<TValue>[];
  placeholder?: string;
  color?: string;
  fixedPlaceholder?: string;
  searchPlaceholder?: string;
  notFoundText?: string;
  multiple?: boolean;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  searchable?: boolean;
  nullable?: boolean;
  name?: string;
}

export function ComboBox<TValue extends string | number>({
  values,
  onChange,
  options,
  multiple,
  color,
  placeholder,
  fixedPlaceholder,
  searchPlaceholder,
  searchable,
  notFoundText,
  className,
  disabled,
  loading,
  name,
  nullable = true,
}: ComboBoxProps<TValue>) {
  const [open, setOpen] = useState(false);

  const selectedLabel = values
    ?.map((val) => options?.find((o) => o.value === val)?.label)
    .join(", ");

  const handleChange = (value: TValue) => {
    if (!multiple) {
      setOpen(false);
      onChange(
        nullable
          ? values?.includes(value)
            ? (null as unknown as TValue)
            : value
          : value,
      );
    } else {
      onChange(value);
    }
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          aria-labelledby={name}
          variant="outline"
          role="combobox"
          size="sm"
          aria-haspopup="menu"
          aria-expanded={open}
          disabled={disabled}
          className={`w-full justify-between ${className}`}
        >
          <div className="flex min-w-0 grow items-center space-x-2">
            {color && (
              <span
                className="size-4 shrink-0 rounded-sm"
                style={{ backgroundColor: color }}
              />
            )}
            <span className="truncate">
              {(fixedPlaceholder || selectedLabel || placeholder) ?? "Select..."}
            </span>
          </div>
          {loading ? (
            <Loader2 className="text-primary-200 group-hover:text-primary-400 size-5 shrink-0 animate-spin" />
          ) : (
            <ChevronDown
              className={`text-primary-200 group-hover:text-primary-400 size-4 shrink-0 transition-transform ${open ? "rotate-180" : "rotate-0"} `}
            />
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="p-0">
        <Command
          filter={(value, search) => {
            const label = options?.find(
              (o) => o.value.toString().toLowerCase() === value,
            )?.label;
            if (
              label?.toLowerCase().includes(search.toLowerCase()) ||
              value.includes(search.toLowerCase())
            ) {
              return 1;
            }
            return 0;
          }}
        >
          {searchable ? (
            <CommandInput placeholder={searchPlaceholder} />
          ) : (
            <div tabIndex={0} aria-hidden="true" className="focus:outline-none" />
          )}
          <CommandList>
            <CommandEmpty>{notFoundText ?? "No options found."}</CommandEmpty>
            <CommandGroup>
              {options?.map((opt) => {
                const isSelected = values?.includes(opt.value);
                return (
                  <CommandItem
                    key={opt.value}
                    onSelect={() => handleChange(opt.value)}
                    value={opt.value.toString()}
                    className={`hover:bg-gray-200 ${isSelected ? "bg-primary-20 aria-selected:bg-primary-20" : ""} `}
                  >
                    <Check
                      className={`text-primary-400 mr-2 size-4 shrink-0 ${isSelected ? "opacity-100" : "opacity-0"} `}
                    />
                    {opt.label}
                  </CommandItem>
                );
              })}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}

export const useComboBoxState = <TValue extends string | number>({
  initialValues,
  multiple,
}: {
  initialValues?: TValue[] | TValue;
  multiple: boolean;
}) => {
  const [values, setValues] = useState<TValue[]>(
    Array.isArray(initialValues)
      ? initialValues
      : initialValues
        ? [initialValues]
        : [],
  );

  const selectedValues = new Set<TValue>(values);

  const onChange = (item: TValue | TValue[]) => {
    if (!multiple) {
      if (Array.isArray(item)) {
        if (item[0] !== undefined) {
          setValues([item[0]]);
        }
      } else {
        setValues([item]);
      }
    } else {
      if (Array.isArray(item)) {
        setValues(item);
      } else {
        if (selectedValues.has(item)) {
          selectedValues.delete(item);
        } else {
          selectedValues.add(item);
        }
        setValues([...selectedValues]);
      }
    }
  };

  const reset = (newValues?: TValue[] | TValue) => {
    if (Array.isArray(newValues)) {
      setValues(newValues);
    } else if (newValues !== undefined) {
      setValues([newValues]);
    } else {
      setValues(
        Array.isArray(initialValues)
          ? initialValues
          : initialValues
            ? [initialValues]
            : [],
      );
    }
  };

  return [values, onChange, reset] as const;
};
