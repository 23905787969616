"use client";

import * as React from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { DayPicker } from "react-day-picker";

import { cn } from "@winclap-platform/ui/utils/tailwind";
import { buttonVariants } from "@winclap-platform/ui/components/button";

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}: CalendarProps) {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn("p-3", className)}
      classNames={{
        months: "flex flex-col",
        month: "",
        month_caption: "w-full text-center mt-1",
        caption_label: "text-sm font-normal leading-4",
        nav: "flex items-center justify-between h-0",
        button_previous: cn(
          buttonVariants({ variant: "icon" }),
          "h-7 w-7 p-0 rounded-md mt-8 ml-1 z-10",
        ),
        button_next: cn(
          buttonVariants({ variant: "icon" }),
          "h-7 w-7 p-0 rounded-md mt-8 mr-1 z-10",
        ),
        month_grid: "w-full mt-4",
        weekdays: "flex justify-end mb-1",
        weekday:
          "text-gray-500 rounded-md w-9 font-normal text-sm dark:text-gray-400 p-0",
        week: "flex w-full mt-0.5",
        day: "text-center h-9 w-9 text-sm p-0 relative focus-within:relative",
        day_button: cn(
          buttonVariants({ variant: "ghost" }),
          "h-9 w-9 font-normal text-black hover:text-black",
          "group-[.rounded-none]:bg-primary-100 group-[.rounded-none]:hover:bg-primary-200",
        ),
        selected: "[&>button]:bg-primary-100 [&>button]:hover:bg-primary-200",
        today:
          "[&>button]:bg-gray-100 [&>button]:text-gray-900 dark:bg-gray-800 dark:text-gray-50 z-10",
        range_start:
          "bg-[linear-gradient(90deg,_#FFFF_50%,_#F5EEFF_50%)] rounded-none",
        range_end:
          "bg-[linear-gradient(90deg,_#F5EEFF_50%,_#FFFF_50%)] rounded-none",
        outside: "text-gray-500 opacity-50 dark:text-gray-400",
        disabled:
          "rounded-md bg-gray-200 text-gray-500 opacity-50 dark:text-gray-400",
        range_middle:
          "[&>button]:bg-transparent aria-selected:bg-primary-20 aria-selected:text-gray-900 dark:aria-selected:bg-primary-20 dark:aria-selected:text-gray-50 rounded-none",
        hidden: "invisible",
        week_number:
          "text-gray-500 text-[0.7rem] flex justify-center align-center text-center leading-9 h-9 w-9 block pointer-events-none",
        ...classNames,
      }}
      components={{
        Chevron: (props) => {
          if (props.orientation === "left") {
            return <ChevronLeft className="size-4" />;
          }
          return <ChevronRight className="size-4" />;
        },
      }}
      {...props}
    />
  );
}
Calendar.displayName = "Calendar";

export { Calendar };
